import { Image } from "react-grid-gallery";

export const images = [
  {
    src: require("../assets/images/MSK_7425.JPG"),
    original: require("../assets/images/MSK_7425.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7428.JPG"),
    original: require("../assets/images/MSK_7428.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7434.JPG"),
    original: require("../assets/images/MSK_7434.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7436.JPG"),
    original: require("../assets/images/MSK_7436.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7438.JPG"),
    original: require("../assets/images/MSK_7438.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7443.JPG"),
    original: require("../assets/images/MSK_7443.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },
  {
    src: require("../assets/images/MSK_7444.JPG"),
    original: require("../assets/images/MSK_7444.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7452.JPG"),
    original: require("../assets/images/MSK_7452.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7459.JPG"),
    original: require("../assets/images/MSK_7459.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },
  {
    src: require("../assets/images/MSK_7460.JPG"),
    original: require("../assets/images/MSK_7460.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },

  {
    src: require("../assets/images/MSK_7462.JPG"),
    original: require("../assets/images/MSK_7462.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },
  {
    src: require("../assets/images/MSK_7463.JPG"),
    original: require("../assets/images/MSK_7463.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },
  {
    src: require("../assets/images/MSK_7464.JPG"),
    original: require("../assets/images/MSK_7464.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },
  {
    src: require("../assets/images/MSK_7465.JPG"),
    original: require("../assets/images/MSK_7465.JPG"),
    width: 1980,
    height: 1080,
    // tags: [
    //   { value: "", title: "" },
    //   { value: "", title: "" },
    // ],
    // caption: "",
  },
];
