import "firebase/firestore";
import "firebase/auth"
// The Cloud Functions for Firebase SDK to create Cloud Functions and triggers.
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs,serverTimestamp,addDoc } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcspp9mbBrbrW_GwoReIxqEqowGlYCw2g",
  authDomain: "bodhi-spa-be89c.firebaseapp.com",
  projectId: "bodhi-spa-be89c",
  storageBucket: "bodhi-spa-be89c.appspot.com",
  messagingSenderId: "620032922061",
  appId: "1:620032922061:web:595e88592332e671a87484",
  measurementId: "G-DXHTP7YF6M",
};

const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export default firebase;
