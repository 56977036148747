import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import { Gallery } from "react-grid-gallery";
import "../../node_modules/yet-another-react-lightbox/dist/styles.css";
import { images } from "./images";
import gallery_2 from "../assets/images/gallery_2.jpeg";
import { useNavigate } from "react-router-dom";

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));

const GalleryComponent = () => {
  const [index, setIndex] = useState(-1);
  const navigate = useNavigate();

  const handleClick = (index, item) => setIndex(index);
  const navigateToContacts = (e) => {
    e.preventDefault();
    navigate("/contact");
  };
  return (
    <>
      <div class="ftco-blocks-cover-1">
        <div
          class="site-section-cover overlay"
          data-stellar-background-ratio="0.5"
          style={{ backgroundImage: `url(${gallery_2})` }}
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-7">
                <h1 class="mb-3">Gallery</h1>
                <p>
                  The spa gallery provides a variety of images of the spa,
                  including the lobby, treatment rooms, pool, and outdoor areas.
                  The gallery is an excellent way to get a feel for the spa and
                  see what it has to offer.
                </p>
                <p>
                  <a onClick={navigateToContacts} class="btn btn-primary">
                    Contact Us
                  </a>
                </p>
              </div>
              <div className="floating-button">
                <a
                  href="tel:+91 96425 63416"
                  class="btn btn-primary btn-white callus ml-2"
                  target="_blank"
                >
                 <svg class="h-8 w-8 text-red-500" style={{marginRight:"5px"}}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />  <path d="M15 7a2 2 0 0 1 2 2" />  <path d="M15 3a6 6 0 0 1 6 6" /></svg>
                  Call Us
                </a>

                <a
                  aria-label="Chat on WhatsApp"
                  // href="https://wa.me/919642563416"
                  href="//api.whatsapp.com/send?phone=919642563416&text=Hi, I'would like to enquire regarding Bodhi Spa accommodations"
                  target="_blank"
                  className="whatsapp"
                >
                  <img
                    alt="Chat on WhatsApp"
                    src={require("../assets/images/WhatsAppButtonGreenSmall.png")}
                  />
                  <a />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="site-section">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="heading-border-bottom font-weight-bold serif text-black mb-5 text-center">
                See Our galleries
              </h2>
            </div>
          </div>
          <div>
            <Gallery
              images={images}
              onClick={handleClick}
              enableImageSelection={false}
            />
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
          <div class="row" style={{ display: "none" }}>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_1.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_1.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_2.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_2.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_3.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_3.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_4.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_4.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_2.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_2.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_3.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_3.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_4.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_4.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-5">
              <a href="images/img_1.jpg" data-fancybox="gal" class="">
                <img
                  src={require("../assets/images/img_1.jpg")}
                  alt="Image"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="site-section services_294491" style={{ display: "none" }}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 text-center">
              <h2 class="serif text-white font-weight-bold text-primary">
                Get 20% discount{" "}
              </h2>
              <p class="lead">
                Lorem ipsum doltnor sit amet, consectetur adipisicing elit.
              </p>
              <p>
                <a href="#" class="btn btn-primary">
                  Contact us
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryComponent;
