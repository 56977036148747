import firebase, { db } from "../../src/firebase";
import { addDoc, collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button, Modal } from "react-bootstrap";

const MyModel = (props) => (
  <Modal show={true} onHide={props.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Translations</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>Filter:</h4>
      <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
      <hr />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onClose}>Close</Button>
    </Modal.Footer>
  </Modal>
);

export default function Form() {
  const functions = getFunctions();
  const [showModal, setShowModal] = useState(true);

  const hideModal = () => {
    setShowModal(false);
  };
  const [state, setState] = useState({
    message: "",
    firstName: "",
    subject: "",
    email: "",
    lastName:""
  });

  const userCollectionRef = collection(db, "contactdata");

  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const { message, firstName, lastName, email } = state;

  const handleState = ({ target: { id, value } }) =>
    setState({ ...state, [id]: value });

  const handleEmail = (email) => {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    const isValid = pattern.test(email);
    if (isValid) {
      return true;
    } else {
      alert("Invalid email");
      setLoading(false);
      return false;
    }
  };

  const sendEmails = (e) => {
    e.preventDefault();
    setLoading(true);

    //Add user to db
    addDoc(userCollectionRef, {
      name: state.firstName,
      email: state.email,
      message: state.message,
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    if (handleEmail(state.email)) {
      const sendEmail = httpsCallable(functions, "sendEmail");

      //Send email using sendEmail firebase function
      sendEmail({
        name: state.firstName + " " + state.lastName,
        email: state.email,
        message: state.message,
      })
        .then((response) => {
          setLoading(false);

          setState({
            message: "",
            firstName: "",
            subject: "",
            email: "",
            lastName:""
          })
          alert("We are grateful for your inquiry and will respond to you shortly.")
          console.log("mail sent successfully", response);
          // setShowModal(true);
        })
        .catch((e) => {
          // setShowModal(false);

          console.log(e);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    const disabled = !state.firstName || !state.email || !state.message;
    setDisabled(disabled);
  }, [state]);

  return (
    <>
      <form onSubmit={(e) => sendEmails(e)}>
        <div className="form-group row">
          <div className="col-md-6 mb-4 mb-lg-0">
            <input
              disabled={loading}
              required
              type="text"
              className="form-control"
              placeholder="First name"
              id="firstName"
              onChange={handleState}
              value={firstName}
            />
          </div>
          <div className="col-md-6">
            <input
              disabled={loading}
              type="text"
              className="form-control"
              placeholder="Last name"
              id="lastName"
              onChange={handleState}
              value={lastName}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <input
              disabled={loading}
              required
              type="text"
              className="form-control"
              placeholder="Email address"
              id="email"
              onChange={handleState}
              value={email}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <textarea
              disabled={loading}
              required
              id="message"
              onChange={handleState}
              value={message}
              name=""
              className="form-control"
              placeholder="Write your message."
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-6 mr-auto">
            <input
              disabled={isDisabled || loading}
              type="submit"
              className="btn btn-block btn-primary text-white py-3 px-5"
              value="Send Message"
            />
          </div>
        </div>
      </form>

      {/* <MyModel isHidden={showModal} onClose={hideModal} /> */}
    </>
  );
}
