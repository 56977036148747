import React, { useState } from "react";
import MSK7437 from "../assets/images/MSK_7437.JPG";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <>
      <div class="ftco-blocks-cover-1">
        <div
          class="site-section-cover overlay"
          data-stellar-background-ratio="0.5"
          style={{ backgroundImage: `url(${MSK7437})` }}
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-7">
                <h1 class="mb-3">Contact Us</h1>
                <p>
                  We offer a variety of packages and specials, so you can find
                  the perfect spa experience for your budget and needs.
                </p>
                <p>
                  <a href="#contact-section" class="btn btn-primary">
                    Get in Touch
                  </a>

                  <a href="tel:+91 96425 63416" class="btn btn-primary ml-2">
                    Call Us
                  </a>
                </p>
              </div>
              <div className="floating-button">
                <a
                  href="tel:+91 96425 63416"
                  class="btn btn-primary btn-white callus ml-2"
                  target="_blank"
                >
                 <svg class="h-8 w-8 text-red-500" style={{marginRight:"5px"}}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />  <path d="M15 7a2 2 0 0 1 2 2" />  <path d="M15 3a6 6 0 0 1 6 6" /></svg>
                  Call Us
                </a>

                <a
                  aria-label="Chat on WhatsApp"
                  // href="https://wa.me/919642563416"
                  href="//api.whatsapp.com/send?phone=919642563416&text=Hi, I'would like to enquire regarding Bodhi Spa accommodations"
                  target="_blank"
                  className="whatsapp"
                >
                  <img
                    alt="Chat on WhatsApp"
                    src={require("../assets/images/WhatsAppButtonGreenSmall.png")}
                  />
                  <a />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="site-section bg-light" id="contact-section">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-7 text-center mb-5">
              <h2>Get in Touch</h2>
              <p>And Rejuvenate</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 mb-5">
              <ContactForm />
            </div>
            <div class="col-lg-4 ml-auto">
              <div class="bg-white p-3 p-md-5">
                <h3 class="text-black mb-4">Contact Info</h3>
                <ul class="list-unstyled footer-link">
                  <li class="d-block mb-3">
                    <span class="d-block text-black">Address:</span>
                    <span>
                      3rd Floor, Chinnappa Complex, Whitefield - Hoskote Rd,
                      Chaitanya Ananya, Krishnarajapura, Bengaluru, Karnataka
                      560067
                    </span>
                  </li>
                  <li class="d-block mb-3">
                    <span class="d-block text-black">Phone:</span>
                    <span>
                      <a href="tel:+91 96425 63416">+91 96425 63416</a>
                    </span>
                  </li>
                  <li class="d-block mb-3">
                    <span class="d-block text-black">Email:</span>
                    <span>
                      <a href="mailto:bodhispa99@gmail.com">
                        bodhispa99@gmail.com
                      </a>

                      {/* <a
                        href="/cdn-cgi/l/email-protection"
                        class="__cf_email__"
                        data-cfemail="a8c1c6cec7e8d1c7dddaccc7c5c9c1c686cbc7c5"
                      >
                 
                                          [email&#160;protected]
                      </a> */}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
